import { Heading, HydrateWhenInViewport } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';
import { type SxStyleProp } from 'theme-ui';

import { type UspsComponent as UspsComponentType } from '@AuroraTypes';
import { Usp } from '@Components/Usps/Usps';

const ThreeByThreeConfig = (): SxStyleProp => ({
  gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
});

const TwoByTwoConfig = (): SxStyleProp => ({
  gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
});

export const UspsComponent: React.FC<UspsComponentType> = ({ usps }) => {
  const { t } = useTranslation();

  if (!usps?.length) {
    return null;
  }

  const isOddOrByThree = usps.length % 3 === 0 || usps.length % 2 === 1;

  return (
    <HydrateWhenInViewport>
      <section data-id="usps">
        <Heading
          as="h2"
          variant="largebold"
          sx={{
            marginBottom: '2xl',
          }}
        >
          {t('bookWithConfidence')}
        </Heading>

        <div
          sx={{
            display: 'grid',
            rowGap: '2xl',
            columnGap: 's',
            ...(isOddOrByThree ? ThreeByThreeConfig() : TwoByTwoConfig()),
          }}
        >
          {usps.map((usp, i) => (
            <Usp
              {...usp}
              key={i}
            />
          ))}
        </div>
      </section>
    </HydrateWhenInViewport>
  );
};
